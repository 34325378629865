import { Field } from '@wix/ambassador-blog-v3-post/types';
import { listDemoPosts } from '@wix/ambassador-blog-v3-post/http';
import {
  BLOG_HEADER_TOTAL_RESULTS,
  getCurrentUser,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_USE_DEMO_SERVICE } from '@wix/communities-blog-experiments';
import { fetchCurrentUser } from '../store/auth/fetch-current-user';
import { encodeURISlug } from './slug';
import { getLocale, getQueryLocale } from '../selectors/locale-selectors';
import { getIsCreatedWithResponsiveEditor } from '../selectors/app-settings-selectors';
import { normalizePostV3 } from './post-utils';

const DEMO_POSTS_URL =
  'https://static.parastorage.com/unpkg-semver/communities-blog-statics/assets';

let _posts;
export const getDemoPosts = async ({
  getState,
  dispatch,
  httpClient,
  aggregatorRequest,
  wixCodeApi,
  page = 1,
  pageSize = 6,
  slugs,
  excludeIds = [],
  language,
  featuredOnly,
  categoryId,
  tagId,
}) => {
  const state = getState();
  if (isDemoPostsDisabled(state)) {
    return toResponse([]);
  }

  const isFiltered = featuredOnly || categoryId || tagId;

  const [hasPosts] = await Promise.all([
    isFiltered && getHasRealPosts({ aggregatorRequest, language }),
    !getCurrentUser(state) &&
      dispatch(fetchCurrentUser(wixCodeApi.user.currentUser.instance)),
    !_posts &&
      fetchDemoPosts({ state, dispatch, httpClient, wixCodeApi, language }),
  ]);

  if (isFiltered) {
    if (hasPosts) {
      return toResponse([]);
    }
    pageSize = 1;
  }

  if (excludeIds.some((id) => !_posts.find((x) => x.id === id))) {
    return toResponse([]);
  }

  const posts = Array.isArray(slugs)
    ? _posts.filter((x) => slugs.includes(encodeURISlug(x.slug)))
    : _posts.slice((page - 1) * pageSize, page * pageSize);

  const firstPublishedDate = new Date();
  firstPublishedDate.setMinutes(firstPublishedDate.getMinutes() - 1);
  const lastPublishedDate = new Date();
  lastPublishedDate.setHours(firstPublishedDate.getHours() - 24);
  const owner = getCurrentUser(getState()) || {};

  return toResponse(
    posts.map((post) => {
      post.firstPublishedDate = firstPublishedDate.toISOString();
      post.lastPublishedDate = lastPublishedDate.toISOString();
      post.owner = owner;
      if (!post.coverImage) {
        post._id = post._id || post.id;
        post.status = 'published';
        return normalizePostV3(post);
      }
      return post;
    }),
    _posts.length,
  );
};

const fetchDemoPosts = async ({ state, httpClient, language }) => {
  if (!language) {
    language = getQueryLocale(state) || getLocale(state);
  }

  if (isExperimentEnabled(state, EXPERIMENT_USE_DEMO_SERVICE)) {
    try {
      const { data } = await httpClient.request(
        listDemoPosts({
          language,
          fieldsToInclude: [Field.CONTENT],
        }),
      );
      _posts = data.posts;
    } catch (ex) {
      _posts = [];
    }
  } else {
    // TODO: remove when EXPERIMENT_USE_DEMO_SERVICE is merged
    const url = `${DEMO_POSTS_URL}/${
      getIsCreatedWithResponsiveEditor(state)
        ? 'demo-posts-x.json'
        : 'demo-posts.json'
    }`;
    const { data } = await httpClient.get(url);
    _posts = data;
  }
};

const isDemoPostsDisabled = (state) => {
  if (isExperimentEnabled(state, EXPERIMENT_USE_DEMO_SERVICE)) {
    return false;
  }
  return (
    getLocale(state) !== 'en' ||
    !isExperimentEnabled(state, 'specs.wixBlog.StandardDemoContentInEditor')
  );
};

const toResponse = (posts, total = 0) => ({
  posts,
  headers: { [BLOG_HEADER_TOTAL_RESULTS.toLowerCase()]: total },
});

const getHasRealPosts = async ({ language, aggregatorRequest }) => {
  const { posts } = await aggregatorRequest(
    `/v1/post-list-widget/render-model?${[
      'postLimit=1',
      language && `language=${language}`,
      'fields=posts',
    ]
      .filter(Boolean)
      .join('&')}`,
    { throwOnInvalidJson: true },
  );
  return posts.body.length > 0;
};
