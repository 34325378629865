import {
  getWixDataCategoryId,
  getWixDataTagId,
  handleAggregatorResponseWithHeaders,
  POST_LIST_SETTINGS_PARAMS,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_OOI_POSTS_WITH_SITE_TIMEZONE } from '@wix/communities-blog-experiments';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import { getPostListWidgetPageSize } from '../selectors/post-list-widget-page-size';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { handleTranslationsResponse } from '../../common/store/translations/translations-actions';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { handleCategoriesResponse } from '../../common/store/categories/fetch-categories';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { getTotalResults } from '../../common/services/pagination';
import setPosts from '../../common/actions/set-posts';
import { setIsLoading } from '../../common/store/is-loading/is-loading-actions';
import { setPostCount } from '../../common/store/post-count/set-posts-count';
import timezoneService from '../../common/services/timezone';
import getEnvironment from '../../common/services/get-environment';
import { getDemoPosts } from '../../common/services/demo-posts';
import { getTimezone } from '../../common/store/basic-params/basic-params-selectors';

const fetchPostListRenderModel = ({
  aggregatorRequest,
  state,
  page,
  fields,
}) => {
  const isPostWithSiteTimezoneEnabled = isExperimentEnabled(
    state,
    EXPERIMENT_OOI_POSTS_WITH_SITE_TIMEZONE,
  );
  const postLimit = getPostListWidgetPageSize(state);
  const featuredOnly = getAppSettingsValue({
    state,
    key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath,
  });
  const categoryId = getWixDataCategoryId(state);
  const tagId = getWixDataTagId(state);
  const language = getQueryLocale(state);
  const timezone = getTimezone(state) || timezoneService.timezone();

  return aggregatorRequest(
    `/v1/post-list-widget/render-model?${[
      postLimit && `postLimit=${postLimit}`,
      featuredOnly && `featuredOnly=${featuredOnly}`,
      categoryId && `categoryId=${categoryId}`,
      tagId && `tagId=${tagId}`,
      language && `language=${language}`,
      page && `page=${page}`,
      isPostWithSiteTimezoneEnabled && `timezone=${timezone}`,
      fields && `fields=${fields.join(',')}`,
    ]
      .filter(Boolean)
      .join('&')}`,
    { throwOnInvalidJson: true },
  );
};

const handlePostListPostsResponse = (postsResponse, { page = 1 } = {}) => (
  dispatch,
  getState,
  { wixCodeApi, httpClient, aggregatorRequest }
) => {
  dispatch(setIsLoading('postListPosts', undefined, true));

  return dispatch(handleAggregatorResponseWithHeaders(postsResponse))
    .then(async ({ body, headers }) => {
      if (!body.length && getEnvironment(wixCodeApi).isEditorSegment) {
        const state = getState();
        const fake = await getDemoPosts({
          httpClient,
          aggregatorRequest,
          getState,
          dispatch,
          wixCodeApi,
          page,
          pageSize: getPostListWidgetPageSize(state),
          featuredOnly: getAppSettingsValue({ state, key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath }),
          categoryId: getWixDataCategoryId(state),
          tagId: getWixDataTagId(state),
          language: getQueryLocale(state),
        });
        body = fake.posts;
        headers = fake.headers;
      }
      dispatch(setPosts(body));
      dispatch(setPostCount(getTotalResults(headers)));
    })
    .then(() => dispatch(setIsLoading('postListPosts', undefined, false)))
    .catch(() => dispatch(setIsLoading('postListPosts', undefined, false)));
};

export const fetchInitialData = () => async (
  dispatch,
  getState,
  { aggregatorRequest, appParams },
) => {
  const state = getState();
  const instanceId = getInstanceId(state);

  const { translations, posts, categories } = await fetchPostListRenderModel({
    aggregatorRequest,
    state,
    appParams,
  });

  await dispatch(handleTranslationsResponse(translations));
  if (categories) {
    await dispatch(handleCategoriesResponse(categories));
  }
  await dispatch(fetchTopology(instanceId));
  await dispatch(handlePostListPostsResponse(posts));
};

export const fetchPostListPosts = (page) => async (
  dispatch,
  getState,
  { aggregatorRequest, appParams },
) => {
  const state = getState();

  const { posts } = await fetchPostListRenderModel({
    aggregatorRequest,
    state,
    appParams,
    page,
    fields: ['posts'],
  });

  await dispatch(handlePostListPostsResponse(posts, { page }));
};

export const fetchPostListPostsPromisified = createPromisifiedAction(
  fetchPostListPosts,
  () => null,
  (response) => response.status,
);
